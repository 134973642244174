@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");
html {
  font-size: 62.5%;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  font-size: 16px;
}

.main-layout {
  min-height: 100vh !important;
}

.main-layout .ant-layout-header {
  color: black;
  background: linear-gradient(90deg, #0a2a41 12.76%, #10456b 85.23%);
  top: 0;
  position: sticky;
  z-index: 100;
  height: auto;
  padding: 0px 0px !important;
}
.ant-drawer-header {
  background-color: #ffffff;
}
.ant-drawer-header-title {
  flex-direction: row-reverse;
}
.sigin {
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.try-for-btn {
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;

  /* Primary */
  background: #2396e9;
  border-radius: 40px;
}
.to-toggle {
  transition: background-color 0.3s ease-in-out;
}

.to-toggle.is-past-waypoint {
  background-color: white;
  font-size: 500px;
  border-bottom: 0.5px solid rgb(235, 235, 235);
  /* Change this to the desired background color */
}

.hamburgerIcon {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}
.hamburgerIcon img {
  height: 32px;
  width: 28px;
}

.appfooter {
  text-align: center;
  font-size: 2rem;
  line-height: 3rem;
}

.social-media-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img1,
.img2,
.img3 {
  padding: 2.7rem 4rem 4.9rem 0;
}
.img4 {
  padding: 2.7rem 0 4.9rem 0;
}

.bold-text {
  padding: 0 15px;
}
.last-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  color: white;
  background: linear-gradient(158.73deg, #3956fe 0%, #6de6f1 99.01%);
}

.ant-layout-footer {
  padding: 2rem 0rem 0rem 0rem !important;
  background-color: #ffffff !important;
}
.steps,
.step-image {
  display: flex;
  /* align-items: center; */
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* justify-content: ; */
  cursor: pointer;
}

.steps img,
.step-image img {
  width: 40rem;
  /* margin: auto; */
  size: 50%;
}

.step {
  font-size: 1.6rem;
  padding-bottom: 2rem;
}

.display-image {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.display-image img {
  width: 100%;
  height: auto;
}

.header-info {
  /* font-family: "Open Sans"; */
  font-weight: 700;
  font-size: 3rem;
  line-height: 3rem;
}

.main-layout .main-card {
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0rem 0.4rem 1rem 0.1rem rgba(95, 136, 247, 0.1);
  border-radius: 1rem;
  width: 90rem;
  margin-top: -7.7rem;
}
.main-card .ant-card-body {
  padding: 2.7rem;
}
.main-card .ant-card-body .main-row {
  justify-content: center !important;
}
.main-card .card-details {
  background-color: #f7f9ff;
  border-radius: 1rem;
  text-align: center;
  height: 20rem;
  /* width: 23rem; */
}
.whats-new-container {
  margin-top: 50px;
  width: 90rem;
  margin: auto;
}
.whats-new-each-div {
  width: 33%;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
  cursor: pointer;
  justify-content: center;
}
.whats-new-each-div p {
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
  color: #2396e9;
  margin: 0;
  text-align: left;
  margin-right: 1rem;
}

.anticon {
  font-size: 1.28rem !important;
}

.browse-card {
  width: 92rem;
  margin: 6rem 0rem;
}
.browse-card .ant-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.browse-card .single-card {
  width: 48%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2rem !important;
  box-shadow: 0px 4px 10px 1px rgba(95, 136, 247, 0.1);
}
.browse-card .card-details {
  border-radius: 1rem;
  cursor: pointer;
}

.browse-media {
  height: 4rem;
  width: 4rem;
}

.question-div {
  margin-top: 5rem;
}

.question-div .question-card {
  width: 65rem;
  margin-top: 2.2rem;
  box-shadow: 0rem 0.4rem 1rem 0.1rem rgba(95, 136, 247, 0.1);
  border-radius: 1rem;
}
.frequent-list .question-card:hover,
.question-div .question-card:hover {
  cursor: pointer;
}
.question-card h3 {
  font-family: "Poppins";
  font-weight: 600;
  margin: 0;
  font-size: 1.7rem;
}
.question-card .ans-row {
  margin-top: 0.8rem;
}

.header-content {
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* search page */
.results-div {
  width: 70%;
  margin: auto;
  padding: 3rem;
  box-shadow: 0px 4px 10px 1px rgba(95, 136, 247, 0.1);
  border-radius: 1rem;
}

.results-div .each-result-div {
  margin-bottom: 3rem;
  border-bottom: 1px solid #666666;
}
.each-result-div .title-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.each-result-div .belongs-to {
  color: #666666;
  margin-left: 20px;
}
.results-div .each-result-div:last-child {
  margin-bottom: 0rem;
}

.each-result-div .highlightclass {
  background-color: #faf4e1;
}
.results-div .content-of-page {
  color: #666666;
  margin-top: 0.5rem;
  font-weight: 200;
  font-size: 16px;
}

.go-back a,
.ant-breadcrumb {
  font-size: 16px !important;
  margin-bottom: 2rem;
}

.tutorial-content {
  padding: 4rem 10rem;
}
.tutorial-content .card-details {
  background: #ffffff;
  box-shadow: 0px 4px 10px 1px rgba(95, 136, 247, 0.1);
  border-radius: 1rem;
  cursor: pointer;
}

.tutorial-content .ant-card-body {
  padding: 0 !important;
}
.video-image {
  position: relative;
  width: 100%;
}
.video-icon {
  padding: 1rem;
}
.play-video {
  width: 100%;
  height: 50rem;
  /* border-radius: 1rem; */
  border: none;
  margin-bottom: 2rem;
}
.play-video-image {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}
.video-topic {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.5rem;
  color: #162a62;
}
.video-tutorial {
  margin-top: 3rem;
}

.video-desc {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #666666;
}
.video-time {
  position: absolute;
  right: 5px;
  bottom: 5px;
  background: rgba(51, 51, 51, 0.7);
  border-radius: 1px;
  color: #ffffff;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding: 0.2rem;
}

.video-title {
  padding: 2rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #162a62;
}

.video-content {
  display: flex;
  flex-direction: column;
}

.w-setting {
  margin-bottom: 15rem;
}

.first-column {
  font-family: "Poppins";
  line-height: 2.1rem;
  color: #666666;
}
.started-items {
  overflow-y: auto;
  padding: 0rem 0.8rem;
  font-size: 1.2rem;
  min-height: 480px;
}

.started-items .ant-menu-item {
  overflow: visible !important;
  white-space: wrap;
  height: fit-content !important;
  line-height: normal !important;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  margin-bottom: 14px !important;
}

.started-items ol,
.second-column ol {
  padding: 0;
  margin: 0;
  padding-left: 1.4rem;
}
.second-column li {
  margin-bottom: 1rem;
}
.started-items p {
  margin-bottom: 0.5rem;
  color: #2396e9;
  text-align: start;
}

.started-items .ant-menu-title-content {
  margin-top: 0;
  font-size: 16px !important;
  line-height: 1rem !important;
}

.started-item-link {
  color: #666666;
}
.ant-menu-vertical {
  height: 0px;
  line-height: 1.6rem;
}

.step-list {
  margin-left: 2rem;
  padding-top: 0.7rem;
}

.no-deco {
  text-decoration: none;
  color: #333333;
}
.no-deco:hover {
  color: #5f88f7;
}

.step-list li {
  padding-top: 1rem;
}
.step-list li::marker {
  color: black;
}

.workspace-content {
  font-size: 1.3rem;
  line-height: 2rem;
  color: #666666;
  padding-bottom: 1rem;
}

.link-started {
  color: #666666;
}
.link {
  font-size: 1.8rem;
  border-bottom: 0.3px dashed #d4d3d3;
  color: #666666;
}

.side-menu {
  font-size: 1.2rem !important;
}
.side-menu .side-subitems {
  padding-left: 1.8rem;
}

.side-subitems .ant-menu-item a:hover {
  color: #2396e9 !important;
  /* text-decoration: underline; */
  padding: 0 !important;
  margin: 0 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-item {
  padding: 0 !important;
  margin: 0 !important;
  line-height: 1.6rem;
}

.ant-menu-item svg,
.ant-menu-item img {
  margin-left: 1rem;
}

.side-subitems .ant-menu-item a {
  color: #666666 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.line {
  line-height: 0.2rem;
  padding-bottom: 1.8rem;
}

.frequent-list .question-card {
  width: 100%;
  margin-top: 2.2rem;
  box-shadow: 0rem 0.4rem 1rem 0.1rem rgba(95, 136, 247, 0.1);
  border-radius: 1rem;
}

.ant-modal-content {
  background-color: rgb(161, 159, 159, 0.8) !important;
}
.ant-modal-body {
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.anticon {
  color: black !important;
  font-size: 2.5rem;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(53, 53, 53, 0.8);
}

.modal-content {
  margin: auto;
  max-width: 100%;
  animation-name: zoom;
  animation-duration: 0.6s;
  width: 90%;
  margin: auto;
}

.modal-header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 102;
  width: 100%;
  height: 10%;
}

.modal-header button {
  background-color: transparent;
  border: none;
}

.modal-header button:disabled {
  color: red;
  border: none;
}

.close {
  position: absolute;
  top: 1rem;
  right: 2rem;
  transition: 0.3s;
}

.zoom-in {
  position: absolute;
  top: 1rem;
  right: 7rem;
  transition: 0.3s;
}

.zoom-out {
  position: absolute;
  top: 1rem;
  right: 12rem;
  transition: 0.3s;
}

.zoom-out .anticon,
.zoom-in .anticon,
.close .anticon {
  color: white !important;
  font-size: 2.4rem !important;
}

.zoom-in:hover,
.zoom-out:hover,
.close:hover,
.close:focus {
  color: #bbb;
  background-color: black;
  cursor: pointer;
}

.column h3 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.7rem;
}

.main-footer {
  font-family: "Poppins";
  font-weight: 600;
}
.main-tag-div {
  position: relative;
}
.left-arrow {
  position: absolute;
  left: -1.5rem;
  top: 2rem;
}
.right-arrow {
  position: absolute;
  right: -1.5rem;
  top: 2rem;
}
.menu-tags {
  overflow-x: scroll;
  scroll-behavior: smooth;
  white-space: nowrap;
  width: 92rem;
  margin: auto;
  margin-bottom: 3rem;
}
.menu-tags::-webkit-scrollbar {
  background: transparent;
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

.tut-tags {
  border: 1px solid #dbdbdb !important;
  border-radius: 30px !important;
  margin: 1rem;
  color: #999999 !important;
}

.tut-tags:hover {
  border: 1px solid #2396e9 !important;
  color: #2396e9 !important ;
}

.tut-tags-active {
  border: 1px solid #2396e9 !important;
  color: #2396e9 !important ;
  border-radius: 30px !important;
  margin: 1rem;
  font-weight: 600 !important;
}
.ant-btn > span {
  display: inline-block;
}

.third-column {
  width: 100%;
  padding: 0 !important;
  margin: 0;
}
.third-column .content-card {
  border: 0.2px solid #9c9c9c;
  border-radius: 1rem;
  background-color: #ebebeb;
  width: 100%;
  position: sticky !important;
  top: 3rem;
}
.content-card p {
  font-size: 1.2rem;
  padding: 0.2rem 0.4rem;
  margin-bottom: 0.2rem;
  font-family: "Roboto" !important;
}
.content-card p:hover {
  cursor: pointer;
  color: #2396e9;
}
.content-card p:active {
  color: #2396e9;
}
.third-column .card-title-style {
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
}
.third-column .ant-card-body {
  border-top: 0.2px solid #9c9c9c;
}
.third-column .ant-card-head-title {
  padding: 10px 0px;
  font-family: "Roboto" !important;
}
.third-column p:last-child {
  margin-bottom: 0px;
}

.second-column ul.dashed > li:before {
  content: "- ";
  margin-left: -1rem;
}

.second-column ul.dashed > li {
  list-style: none;
}
.second-column ul {
  margin-left: 2rem;
}

.list-roman-style {
  list-style-type: upper-roman;
}
.list-alpha-style {
  list-style-type: lower-alpha;
}
.list-square-style {
  list-style-type: square;
}
.list-circle-style {
  list-style-type: circle;
}

/* report bug  */
.inner-box {
  width: 63.1%;
  margin: auto;
  background-color: #ffffff;
  height: auto;
  padding: 3rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
}

.inner-box .bug-each_div_input {
  margin-bottom: 1.8rem;
}

.inner-box .input_field {
  border: 0.08rem solid #c9c9c9;
  padding: 0.6rem;
  border-radius: 0.3rem;
}
.inner-box .input_select_field,
.inner-box .input_field {
  margin-top: 0.5rem;
}

.ant-select-selector {
  border-radius: 0.3rem !important;
  height: auto !important;
  padding: 0.4rem !important;
}
.inner-box .bug_sentbtn {
  background: #5f88f7;
  color: #ffffff;
  outline: none;
  border-radius: 0.3rem;
}
.inner-box .ant-select-suffix > svg {
  color: #666666 !important;
  height: 1rem !important;
}
.inner-box .ant-select-arrow .anticon > svg {
  vertical-align: middle;
}
.inner-box .anticon-upload > svg {
  color: #2396e9;
}

.inner-box .ant-upload-text {
  color: #999999 !important;
  text-align: center;
}
.inner-box .ant-upload-hint {
  text-align: center;
  color: #999999 !important;
}
.inner-box .ant-upload-hint > span {
  color: #08c !important;
}
.inner-box .upload-preview-dashed {
  border: 1px dashed #d9d9d9;
  padding: 1rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.inner-box .upload-preview-dashed .each-upload {
  border: 1px solid gray;
  padding: 1rem;
  display: flex;
  align-items: center;
  width: 49%;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.each-upload .img-name-side {
  display: flex;
  width: 92%;
  align-items: center;
}
.img-name-side .preview-img {
  min-height: 4rem;
  max-height: 4rem;
  min-width: 4.5rem;
  max-width: 4.5rem;
}
.img-name-side p {
  font-size: 1rem !important;
  margin-left: 1rem;
  text-overflow: ellipsis;
}
.each-upload .icon {
  cursor: pointer;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

::marker {
  color: #2396e9;
}

@media only screen and (min-width: 1025px) and (max-width: 1230px) {
  /* .started-content {
    padding: 3rem 8rem;
  } */
  .main-card {
    width: 80vw !important;
  }
  .question-div .question-card {
    width: 80vw;
  }
  .inner-box {
    width: 73%;
    margin: auto;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1024px) {
  /* .started-content {
    padding: 2rem 6rem;
  } */

  .started-items .ant-menu-title-content {
    margin: 0;
  }
  .side-menu .side-subitems li {
    line-height: 1.5rem;
  }
  .main-card .ant-card-body {
    padding: 2.3rem;
  }
  .inner-box {
    width: 90%;
    margin: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  /* .started-content {
    padding: 2rem 6rem;
  } */
  /* .frequent-list {
    width: 49vw;
  } */
  .results-div {
    width: 95%;
    margin: auto;
  }
  .question-div .question-card {
    width: 88vw;
  }

  .main-col {
    padding: 1rem !important;
  }
  .side-menu .side-subitems li {
    line-height: 1.5rem;
  }
  .browse-card {
    width: 93vw !important;
  }
  .inner-box {
    width: 90%;
    margin: auto;
  }
  .inner-box .upload-preview-dashed {
    flex-direction: column;
  }
  .inner-box .upload-preview-dashed .each-upload {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .main-layout .main-card {
    padding: 0rem;
  }
  .side-menu .side-subitems li {
    line-height: 1.5rem;
  }
  .results-div {
    width: 95%;
    margin: auto;
  }

  .started-heading {
    font-size: 3.2rem !important;
    line-height: 4rem !important;
    margin-bottom: 1.5rem;
  }
  .started-items .ant-menu-title-content {
    margin: 0;
  }
  .header-contet {
    width: 85%;
  }

  .menu-tags {
    width: 98%;
    margin: auto;
    overflow: scroll;
    padding: 0rem;
  }
  .whats-new-container {
    width: auto;
    margin: auto;
  }
  .whats-new-each-div {
    margin-bottom: 1rem;

    width: 100%;
  }
  .whats-new-each-div p {
    font-size: 16px;
  }
  .whats-new-each-div img {
    font-size: 16px;
    width: 16px;
  }

  .main-card {
    margin-top: -6rem !important;
    width: 93vw !important;
  }
  .main-card .card-details {
    margin-bottom: 2rem;
  }
  .browse-card {
    width: 93vw !important;
    flex-direction: column;
  }
  .browse-card .single-card {
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 2rem !important;
    box-shadow: 0px 4px 10px 1px rgba(95, 136, 247, 0.1);
  }
  .browse-card .card-details {
    border-radius: 1rem;
    cursor: pointer;
  }

  .column h3 {
    text-align: center;
  }
  .modal-content {
    max-width: 100% !important;
  }
  .second-column {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .dividers {
    display: none !important;
  }
  .ant-divider-horizontal {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .main-row {
    row-gap: 1rem !important;
    padding: 2rem !important;
    margin: auto !important;
  }
  .main-col {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .frequent-questions {
    margin-top: 7.14rem;
  }
  .question-div {
    margin-top: 1.42rem;
  }
  .question-div .question-card {
    width: 90vw;
  }
  .question-card .ant-card-body {
    padding: 1.71rem;
  }
  /* reportbug */
  .inner-box {
    width: 90%;
    margin: auto;
  }
  .inner-box .upload-preview-dashed {
    flex-direction: column;
  }
  .inner-box .upload-preview-dashed .each-upload {
    width: 100%;
  }
}

@media only screen and (min-width: 300px) and (max-width: 480px) {
  html {
    font-size: 60%;
  }

  .side-menu .side-subitems li {
    line-height: 1.5rem;
  }
  .card-details .ant-card-body {
    padding: 2rem;
  }

  .started-heading {
    font-size: 2.4rem !important;
    line-height: 3.2rem !important;
    margin-bottom: 1rem;
  }
  .header-contet {
    width: 90%;
  }
  .logo img {
    width: 21%;
  }
  .logo,
  .sigin {
    font-size: 1.3rem;
  }

  .inner-box {
    width: 95%;
    margin: auto;
  }
  .steps img,
  .step-image img {
    width: 100%;
  }
}

@media only screen and (max-width: 300px) {
  html {
    font-size: 55%;
  }
  .started-heading {
    font-size: 2.6rem !important;
    line-height: 3rem !important;
  }
  .card-details .ant-card-body {
    padding: 2rem;
  }

  .header-contet {
    width: 100%;
  }

  /* reportbug */
  .inner-box {
    width: 98%;
    margin: auto;
  }
  .steps img,
  .step-image img {
    width: 100%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1550px) {
  html {
    font-size: 75%;
  }
}

@media only screen and (min-width: 1551px) and (max-width: 1700px) {
  html {
    font-size: 75%;
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1850px) {
  html {
    font-size: 81.25%;
  }
}

@media only screen and (min-width: 1851px) and (max-width: 2000px) {
  html {
    font-size: 87.5%;
  }
}

@media only screen and (min-width: 2001px) and (max-width: 3500px) {
  html {
    font-size: 93.75%;
  }
}

@media only screen and (min-width: 3500px) {
  html {
    font-size: 100%;
  }
}

@media only screen and (min-width: 1570px) {
  .hide {
    display: none;
  }
}
.numbering{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 25px !important;
  min-width: 27px !important;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #000000;
  color: black;
  margin-right: 0.5rem;
  font-size: 16px;
}